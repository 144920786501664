import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import api from '../../utils/api';
import { navigate } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const AddressSearch = ({ id, placeholder, zoneId, platform }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  // const isMobileDevice =  typeof window !== 'undefined' && window
  //   ? isMobile(window.navigator).phone
  //   : true;

  const onQueryChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setSearchQuery(newValue);
    }
  };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  //
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const inputProps = {
    value: searchQuery,
    onChange: onQueryChange,
    type: 'search',
    placeholder: placeholder || '',
  };

  const renderInputComponent = (inputProps) => (
    <>
      <input {...inputProps} className="text-input right-icon" />
      <FontAwesomeIcon
        className="input-right-icon font-size-xl"
        width={0}
        icon={isLoading ? faSpinner : faSearch}
        spin={isLoading}
      />
    </>
  );

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.description;

  const onSuggestionSelected = (event, { suggestion }) => {
    if (!suggestion?.property?.id) {
      trackCustomEvent({
        category: 'signUp searchBar',
        action: 'click on unknown address',
        label: suggestion.description,
      });

      navigate(
        `/inscription/nouvelle-adresse/${
          platform ? '?&platform=' + platform : ''
        }`,
        {
          state: {
            address: suggestion.description,
          },
        },
      );
    } else {
      trackCustomEvent({
        category: 'signUp searchBar',
        action: 'click on existing address',
        label: suggestion?.property?.id,
      });

      navigate(
        `/inscription/form/${platform ? '?&platform=' + platform : ''}`,
        {
          state: {
            property: suggestion.property,
            zone: suggestion.zone,
          },
        },
      );
    }
  };

  const clearSuggestions = () => {
    setSearchQuery('');
    setSuggestions([]);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (isLoading === true && searchQuery === value) {
      // do nothing
    } else {
      loadSuggestions(value);
    }
  };

  const loadSuggestions = async (address) => {
    if (shouldRenderSuggestions(address)) {
      setIsLoading(true);

      try {
        const response = await api.get(`/addresses/search?address=${address}`);

        setSuggestions(response.data);

        trackCustomEvent({
          category: 'signUp searchBar',
          action: 'search address',
          label: address,
        });
      } catch (e) {
        // do nothing
      }

      setIsLoading(false);
    }
  };

  const shouldRenderSuggestions = (address) => {
    return address.trim().length > 3;
  };

  const renderSuggestion = (suggestion) => {
    if (suggestion?.property?.id) {
      return (
        <div className="text-align-left">
          <div className="bold font-size-md">{suggestion.property.name}</div>
          <div className="pt-1 regular font-size-xs">
            {suggestion.property.units?.[0]?.address?.fullAddress}
          </div>
        </div>
      );
    }

    return (
      <div className="text-align-left">
        <div className="regular font-size-md">{suggestion.description}</div>
      </div>
    );
  };

  //if (isMobileDevice === false) {
  return (
    <Autosuggest
      id={id}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      focusInputOnSuggestionClick={false}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestion
      renderInputComponent={renderInputComponent}
      inputProps={inputProps}
    />
  );
  //}

  // return (
  //   <React.Fragment>
  //     <div>
  //       <input onClick={e => isModalOpen === false ? openModal() : null} className="text-input right-icon" />
  //       <FontAwesomeIcon className="input-right-icon font-size-xl"
  //                        width={0}
  //                        icon={isLoading ? faSpinner : faSearch}
  //                        spin={isLoading}
  //       />
  //     </div>
  //
  //     <Modal
  //       isOpen={isModalOpen}
  //       contentLabel="Modal"
  //       onRequestClose={closeModal}
  //       shouldCloseOnOverlayClick={false}
  //       closeTimeoutMS={
  //         1 /* otherwise the modal is not closed when suggestion is selected by pressing Enter */
  //       }
  //       style={modalStyle}>
  //       <div className="roboto bold font-size-lg text-align-center">Saisissez Votre adresse postale</div>
  //       <div className="text-align-center">
  //         <Autosuggest
  //         suggestions={suggestions}
  //         onSuggestionsFetchRequested={onSuggestionsFetchRequested}
  //         onSuggestionSelected={onSuggestionSelected}
  //         getSuggestionValue={getSuggestionValue}
  //         renderSuggestion={renderSuggestion}
  //         inputProps={inputProps}
  //         alwaysRenderSuggestions={true}
  //         id="scrollable-container-example" />
  //       </div>
  //       <button className="btn" onClick={closeModal}>Cancel</button>
  //     </Modal>
  //   </React.Fragment>
  // );
};

export default AddressSearch;
